import { IonCard, IonCardContent, IonCardHeader, IonTitle } from '@ionic/react'


const TokenViewer: React.FC = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonTitle></IonTitle>
      </IonCardHeader>
      <IonCardContent>STUFF</IonCardContent>
    </IonCard>
  )
}

export default TokenViewer
