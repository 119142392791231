import { BigNumber, Signer } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { create } from 'zustand'
import { BondingCurve } from '../types/ethers-contracts/BondingCurve'
import { BondingCurve__factory } from '../types/ethers-contracts/factories/BondingCurve__factory'

export interface priceData {
  amount: string
  price: number
}

export const fromFloat = (num: string | number) => {
  if (typeof num === 'undefined') {
    return BigNumber.from(0)
  }
  const input = num.toString() || ''
  const rounded = input.split('.')[0]
  const remaining = (input.split('.')[1] || '0').slice(0, 1)
  return BigNumber.from(rounded)
    .mul(BigNumber.from(10).pow(18))
    .add(BigNumber.from(remaining).mul(BigNumber.from(10).pow(17)))
}
export const percentFromFloat = (num: string | number) => {
  if (typeof num === 'undefined') {
    return BigNumber.from(0)
  }
  const input = num.toString() || ''
  const rounded = input.split('.')[0]
  const remaining = (input.split('.')[1] || '0').slice(0, 1)
  return BigNumber.from(rounded)
    .mul(BigNumber.from(10).pow(16))
    .add(BigNumber.from(remaining).mul(BigNumber.from(10).pow(15)))
}

export type BondingCurveTemplate = {
  instance: Record<string, BondingCurve>
  getCurveData: (
    pricingCurve: string,
    count: number,
    delta: BigNumber,
    fee: BigNumber,
    spotPrice: BigNumber,
    initialAmount: BigNumber,
    poolType?: string,
  ) => Record<
    string,
    {
      items: number
      buyPrice: number
      sellPrice: number
      protocolFee: number
    }
  >
  calculateCurveData: (
    signer: Signer,
    pricingCurve: string,
    count: number,
    delta: BigNumber,
    fee: BigNumber,
    spotPrice: BigNumber,
    initialAmount: BigNumber,
    poolType?: string,
  ) => void
  curveData: Record<
    string,
    Record<
      string,
      {
        items: number
        buyPrice: number
        sellPrice: number
        protocolFee: number
      }
    >
  >
  initialize: (address: string, signer: Signer) => void
}

export const useBondingCurve = create<BondingCurveTemplate>((set, store) => ({
  instance: {},
  curveData: {},
  getCurveData: (
    curveAddress,
    items,
    delta,
    baseFee,
    spotPrice,
    liquidity,
    poolType,
  ) => {
    if (
      typeof items === 'undefined' ||
      typeof delta === 'undefined' ||
      typeof liquidity === 'undefined' ||
      typeof poolType === 'undefined'
    ) {
      return {}
    }
    const hash =
      curveAddress +
      'i:' +
      items.toString() +
      delta.toString() +
      baseFee.toString() +
      spotPrice.toString() +
      liquidity.toString() +
      poolType?.toString()
    return store().curveData[hash]
  },
  calculateCurveData: async (
    signer,
    curveAddress,
    items,
    delta,
    baseFee,
    spotPrice,
    liquidity,
    poolType,
  ) => {
    if (
      typeof items === 'undefined' ||
      typeof delta === 'undefined' ||
      typeof liquidity === 'undefined' ||
      typeof poolType === 'undefined'
    ) {
      return undefined
    }
    const hash =
      curveAddress +
      'i:' +
      items.toString() +
      delta.toString() +
      baseFee.toString() +
      spotPrice.toString() +
      liquidity.toString() +
      poolType?.toString()
    set({ curveData: { ...store().curveData, [hash]: {} } })
    const fee = poolType === '2' ? baseFee : 0
    const protocolFee = BigNumber.from('10000000000000000')
    const calculateRecursive = (
      curveInstance: BondingCurve,
      count: number,
      delta: BigNumber,
      spotPrice: BigNumber,
      liquidity: BigNumber,
      buy?: boolean,
    ) => {
      ; (typeof buy === 'undefined' || buy) &&
        curveInstance
          .getBuyInfo(spotPrice, delta, 1, fee, protocolFee)
          .then((info) => {
            if (
              info.error ||
              info.inputValue.eq(0) ||
              count < 1 ||
              poolType === '0'
            ) {
              return
            }
            const curveDataPoint = {
              lpBuyFee: formatEther(
                info.inputValue.sub(info.protocolFee).sub(spotPrice),
              ),
              items: count,
              buyPrice: formatEther(info.inputValue),
              spotPrice: formatEther(spotPrice),
              protocolFee: formatEther(info.protocolFee),
            }
            set({
              curveData: {
                ...store().curveData,
                [hash]: {
                  ...store().curveData[hash],
                  [count]: {
                    ...store().curveData[hash][count],
                    ...curveDataPoint,
                  },
                },
              },
            })
            if (Object.keys(store().curveData[hash]).length<10){
              calculateRecursive(
              curveInstance,
              count - 1,
              info.newDelta,
              info.newSpotPrice,
              liquidity.add(info.inputValue),
              true,
            )}
          })
        ; (typeof buy === 'undefined' || !buy) &&
          curveInstance
            .getSellInfo(spotPrice, delta, 1, fee, protocolFee)
            .then((info) => {
              if (
                info.error ||
                info.outputValue.lt(1000000000000000) ||
                poolType === '1' ||
                liquidity.lt(info.outputValue)
              ) {
                return
              }
              const curveDataPoint = {
                items: count,
                lpSellFee: formatEther(
                  spotPrice.sub(info.outputValue).sub(info.protocolFee),
                ),
                sellPrice: formatEther(info.outputValue),
                lpFee: formatEther(
                  spotPrice.sub(info.outputValue).sub(info.protocolFee),
                ),
                protocolFee: formatEther(info.protocolFee),
              }
              set({
                curveData: {
                  ...store().curveData,
                  [hash]: {
                    ...store().curveData[hash],
                    [count]: {
                      ...store().curveData[hash][count],
                      ...curveDataPoint,
                    },
                  },
                },
              })
              if (Object.keys(store().curveData[hash]).length<10){
                calculateRecursive(
                curveInstance,
                count + 1,
                info.newDelta,
                info.newSpotPrice,
                liquidity.sub(info.outputValue),
                false,
              )}
            })
    }

    let curve = store().instance[curveAddress]
    if (typeof curve === 'undefined') {
      store().initialize(curveAddress, signer)
    }
    curve = store().instance[curveAddress]
    calculateRecursive(curve, items, delta, spotPrice, liquidity)
  },
  initialize: (a, signer) => {
    const curveInstance = BondingCurve__factory.connect(a, signer)
    set({ instance: { ...store().instance, [a]: curveInstance } })
  },
}))
