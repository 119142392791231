import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonModal,
  IonPage,
  IonProgressBar,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import ConnectButton from "../components/ConnectButton";
import Menu, { appPages } from "../components/Menu";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { closeCircle, closeOutline } from "ionicons/icons";

export const Page: React.FC<{ children: any; title?: string }> = ({
  title,
  children,
}) => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { notifications, close, post } = useNotification();
  const { transaction, clear, hook, name } = useTransactionModal();
  const { isSuccess, isLoading } = useWaitForTransaction({
    hash: (transaction as any)?.hash,
  });
  const modal = useRef<HTMLIonModalElement>(null);
  const location = useLocation();
  useEffect(() => {
    isSuccess &&
      (() => {
        modal.current?.dismiss().then(() => {
          hook && hook();
          post({ color: "primary", message: name + " Transaction Complete" });
          clear();
        });
      })();
  }, [transaction, clear, hook, isSuccess, post, name]);
  const sm = window.innerWidth < 576;
  const filtered = !address
    ? appPages.filter((x) => x.url !== "/wallet" && x.url !== "/my-pools")
    : appPages;
  return (
    <IonPage>
      <Menu />
      <IonHeader>
        <IonItem>
          <IonButtons slot="start">
            {sm ? (
              <IonMenuButton />
            ) : (
              filtered.map((appPage, index) => {
                return (
                  <IonItem
                    key={index}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      color={
                        location.pathname !== appPage.url
                          ? "medium"
                          : "tertiary"
                      }
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    {!sm && (
                      <IonText
                        color={
                          location.pathname !== appPage.url
                            ? "medium"
                            : "tertiary"
                        }
                      >
                        {appPage.title}
                      </IonText>
                    )}
                  </IonItem>
                );
              })
            )}
          </IonButtons>
          <IonButtons slot="end">
            <ConnectButton />
          </IonButtons>
        </IonItem>
      </IonHeader>
      <IonContent>{children}</IonContent>
      {typeof transaction !== "undefined" && (
        <IonModal ref={modal} isOpen={typeof transaction != "undefined"}>
          <IonCard>
            <IonCardHeader color={"tertiary"}>
              <IonToolbar color={"tertiary"}>
                <IonButtons slot="start">
                  <IonCardTitle>{name}</IonCardTitle>
                </IonButtons>
                <IonButtons slot="end">
                  <IonButton
                    color={"dark"}
                    onClick={() => {
                      modal.current?.dismiss();
                    }}
                  >
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCardHeader>
            <IonItem
              target="_new"
              href={"https://ftmscan.com/tx/" + transaction?.hash}
            >
              Transaction {transaction?.hash}
            </IonItem>
            <IonCardContent>
              <IonItem>
                <IonProgressBar
                  color={isSuccess ? "favorite" : "primary"}
                  type={isLoading ? "indeterminate" : "determinate"}
                />
              </IonItem>
              <IonText color={"primary"}>{isLoading && "Loading"}</IonText>
              <IonText color={"success"}>{isSuccess && "Success"}</IonText>
              <IonText color={"danger"}>
                {!isLoading && !isSuccess && "Failed"}
              </IonText>
            </IonCardContent>
          </IonCard>
        </IonModal>
      )}
      {notifications &&
        notifications.map((notification, i) => {
          return (
            <IonToast
              animated
              duration={10000}
              onDidDismiss={() => {
                close(i);
              }}
              key={i}
              translucent
              isOpen={true}
              {...notification}
              message={(notification.message || "").replaceAll("ETH", "FTM")}
            />
          );
        })}
    </IonPage>
  );
};
