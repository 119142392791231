import { IonCard, IonCardContent, IonCardHeader, IonTitle } from '@ionic/react'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

interface ContainerProps {
  name: string
}

const CollectionViewer: React.FC = () => {
  const address = useAccount()
  useEffect(() => {
    fetch('https://api.paintswap.finance/v2/userNFTs?user=' + address).then(
      (data) => {
      },
    )
  })
  return (
    <IonCard>
      <IonCardHeader>
        <IonTitle></IonTitle>
      </IonCardHeader>
      <IonCardContent>STUFF</IonCardContent>
    </IonCard>
  )
}

export default CollectionViewer
