import { Signer, ethers } from 'ethers'
import { factory } from 'typescript'
import { create } from 'zustand'
import { Erc721__factory } from '../types/ethers-contracts/factories/Erc721__factory'
import { Erc721 } from '../types/ethers-contracts/Erc721'

export type ERC721Template = {
  instance: Record<string, Erc721>
  initialize: (address: string, signer: Signer|ethers.providers.Provider) => void
}

export const useERC721 = create<ERC721Template>((set, store) => ({
  instance: {},
  initialize: (address, signer) => {
    if (!address || !signer) {
      return
    }
    const contractInstance = Erc721__factory.connect(address, signer)
    set({ instance: { ...store().instance, [address]: contractInstance } })
  },
}))
