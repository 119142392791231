import { ContractTransaction, Transaction } from 'ethers'
import { create } from 'zustand'

export type TransactionTemplate = {
  transaction?: ContractTransaction
  name?: string,
  hook?: () => void
  clear: () => void
  post: (
    tx: void | ContractTransaction,
    name: string,
    hook: () => void|undefined,
  ) => void
}

export const useTransactionModal = create<TransactionTemplate>(
  (set, store) => ({
    name: undefined,
    transaction: undefined,
    post: (transaction, name, hook) => {
      typeof name !== 'undefined' && set({ name })
      typeof transaction !== 'undefined' && set({ transaction })
      typeof hook !== 'undefined' && set({ hook })
    },
    clear: () => {
      set({ transaction: undefined ,name:undefined,hook:undefined})
    },
  }),
)
