import { BigNumber, Signer, ethers } from "ethers";
import { formatEther, formatUnits } from "ethers/lib/utils.js";
import { factory } from "typescript";
import { create } from "zustand";
import { Router__factory } from "../types/ethers-contracts/factories/Router__factory";
import { Router } from "../types/ethers-contracts/Router";

export type PairRouterTemplate = {
  router: Router | undefined;
  address: string;
  initialize: (signer: Signer | ethers.providers.Provider) => void;
  setValue: (key: string, value: any) => void;
};

export const usePairRouter = create<PairRouterTemplate>((set, store) => ({
  router: undefined,
  address: "0xF35b6c95d5dc634B1409df388d28278C3B3c6c23",
  setValue: (key, value) => {
    set({ [key]: value });
  },
  initialize: (signer) => {
    const router = Router__factory.connect(store().address, signer);
    set({ router });
  },
}));
