import { IonButton, IonIcon } from '@ionic/react'
import { BigNumber } from 'ethers'
import { flask, swapHorizontal } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useAccount, useSigner } from 'wagmi'
import { useERC20 } from '../hooks/useERC20'
import { useNotification } from '../hooks/useNotifications'
import { useTransactionModal } from '../hooks/useTransactionModal'
import { formatTokenPrice } from './PoolList'

export const ApproveERC20Button: React.FC<{
  contract: string
  amount: BigNumber
  operator: string
}> = ({ contract, operator, amount }) => {
  const { address } = useAccount()
  const { data: signer } = useSigner()
  const { initialize } = useERC20()
  const { instances } = useERC20()
  const instance = instances[contract]
  const { post: postTx } = useTransactionModal()
  const { post } = useNotification()

  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))
  useEffect(() => {
    signer && contract && initialize(contract, signer)
  }, [signer, contract, initialize])
  useEffect(() => {
    address &&
      instance &&
      instance.allowance(address, operator).then((res) => {
        setAllowance(res)
      })
    address &&
      instance &&
      instance.on('Approval', () => {
        instance.allowance(address, operator).then((res) => {
          setAllowance(res)
        })
      })
  }, [address, instance, contract, initialize, operator])
  return (
    <>
      {instance && allowance.lt(amount) && (
        <IonButton
          onClick={() => {
            instance
              .approve(operator, amount)
              .catch((message) => {
                post({ color: 'danger', message: message.reason })
              })
              .then((tx) =>
                postTx(tx, 'Approve', () => {
                  setAllowance(amount)
                }),
              )
          }}
        >
          Approve <IonIcon icon={flask} />
          <IonIcon icon={swapHorizontal} /> to spend {formatTokenPrice(amount)}
        </IonButton>
      )}
    </>
  )
}
